window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'UA-69936538-1');

var submittedForm = document.getElementById("contact-form-submitted");
if(submittedForm){
  gtag('event', 'contact_form_submission', {
    'event_category': 'engagement'
  });
}

$('.carousel').carousel();

$('#navbarSupportedContent').on('show.bs.collapse', function () {
  $('section').addClass('blurred');
  $('footer').addClass('blurred');
});
$('#navbarSupportedContent').on('hide.bs.collapse', function () {
  $('section').removeClass('blurred');
  $('footer').removeClass('blurred');
});
$('#faq-accordion').on('hide.bs.collapse', function (e) {
  $(e.target).parent().parent().removeClass('open')
  $(e.target).parent().parent().removeClass('shadow')
})
$('#faq-accordion').on('show.bs.collapse', function (e) {
  $(e.target).parent().parent().addClass('open')
  $(e.target).parent().parent().addClass('shadow')
})
function updateURLParameter(url, param, paramVal)
{
    var TheAnchor = null;
    var newAdditionalURL = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";

    if (additionalURL)
    {
        var tmpAnchor = additionalURL.split("#");
        var TheParams = tmpAnchor[0];
            TheAnchor = tmpAnchor[1];
        if(TheAnchor)
            additionalURL = TheParams;

        tempArray = additionalURL.split("&");

        for (var i=0; i<tempArray.length; i++)
        {
            if(tempArray[i].split('=')[0] != param)
            {
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
        }
    }
    else
    {
        var tmpAnchor = baseURL.split("#");
        var TheParams = tmpAnchor[0];
            TheAnchor  = tmpAnchor[1];

        if(TheParams)
            baseURL = TheParams;
    }

    if(TheAnchor)
        paramVal += "#" + TheAnchor;

    var rows_txt = temp + "" + param + "=" + paramVal;
    return baseURL + "?" + newAdditionalURL + rows_txt;
}
/**
 * Show live search results in FAQ page
 */
function showSearchResults(data){
  var wrapper = document.getElementById('faq-accordion');

  if(!data.length){
    wrapper.innerHTML = '';
    var noresNode = document.createElement('div');
    noresNode.className = 'text-center';
    noresNode.id = 'no-results-container';

    var h3 = document.createElement('h3');
    h3.innerText = 'Inga sökresultat';

    var noresText = document.createElement('p');
    noresText.innerText = 'Försök att söka efter något annat';

    noresNode.appendChild(h3);
    noresNode.appendChild(noresText);
    wrapper.appendChild(noresNode);
    return;

  }

  wrapper.innerHTML = '';
  for (var i = 0; i < data.length; i++) {

    var node = document.createElement('article');
    node.className = 'accordion';

    var card = document.createElement('div');
    card.className = 'card rounded';

    var header = document.createElement('header');
    header.className = 'card-header faq-item';
    header.id = 'heading-'+i;

    var title = document.createElement('h2');

    var button = document.createElement('button');
    button.className = 'btn btn-link btn-block align-items-center justify-content-between justify-content-between d-flex collapsed text-left px-0 px-md-2';
    button.innerText = data[i].title;
    button.type = 'button';
    button.dataset.toggle = 'collapse';
    button.dataset.target = '#collapse-'+i;
    button.setAttribute("aria-expanded", "true");
    button.setAttribute("aria-controls", "#collapse-"+i);

    var icon1 = document.createElement('i');
    icon1.className = 'fas fa-plus';

    var icon2 = document.createElement('i');
    icon2.className = 'fas fa-minus';

    button.appendChild(icon1);
    button.appendChild(icon2);
    title.appendChild(button);
    header.appendChild(title);
    card.appendChild(header);

    var collapse = document.createElement('div');
    collapse.className = 'collapse';
    collapse.id = 'collapse-'+i;
    collapse.dataset.parent = '#faq-accordion';

    var body = document.createElement('div');
    body.className = 'card-body';

    var padding = document.createElement('div');
    padding.className = 'px-2';

    var content = document.createElement('p');
    content.innerHTML = data[i].body;

    padding.appendChild(content);
    body.appendChild(padding);
    collapse.appendChild(body);
    card.appendChild(collapse);
    node.appendChild(card);
    wrapper.appendChild(node);
  }
}
var delayTimer;
var ajax = null;
function doSearch(query){

  if (ajax && typeof ajax.abort === 'function') {
    ajax.abort(); // abort previous requests
  }
  // Get search form url
  var url = document.getElementById('search-form').action;
  // init XMLHttpRequest
  ajax = new XMLHttpRequest();
  ajax.onreadystatechange = function() {
    // completed request
    if (this.readyState === 4) {
      try {
        var json = JSON.parse(this.responseText)
      } catch (e) {
        console.log('JSON parse error:', e);
        return;
      }
      // Show results on UI
      showSearchResults(json);

      clearTimeout(delayTimer);
      delayTimer = setTimeout(function() {
        gtag('event', 'search', { 'search_term': query });
      }, 1000);
    }
  }
  ajax.open('GET', url+'?q='+query, true);
  // Request JSON format
  ajax.setRequestHeader('Accept', 'application/json');
  ajax.send();
}
// FAQ search input
var searchinput = document.getElementById('search-input');
if(searchinput)
  // If exists, trigger doSearch everytime when input changes.
  searchinput.oninput = function() {
    var query = this.value;
    doSearch(query);
    var newURL = updateURLParameter(window.location.href, 'q', query);
    window.history.replaceState('', '', newURL);
  }

$(document).ready(function() {
  $('#sign-up-form-mini').submit(function(event) {
    var company = $('input[name=company]').val();
    var email = $('input[name=email]').val();
    var formData = 'company='+company+'&email='+email;
    var action = document.getElementById('sign-up-form-mini').action;
    var method = document.getElementById('sign-up-form-mini').method;
    // Disable inputs and buttons and show loading text
    var submitButton = document.getElementById('sign-up-form-mini-submit');
    var submitButtonDefaultText = submitButton.innerHTML;
    submitButton.innerHTML = 'Laddar..';

    submitButton.disabled = true;
    $('input[name=company]').prop( "disabled", true );
    $('input[name=email]').prop( "disabled", true );

    // Make request
    var contactAjax = new XMLHttpRequest();
    contactAjax.onreadystatechange = function() {
      // completed request
      if (this.readyState === 4) {
        try {
          var json = JSON.parse(this.responseText)
        } catch (e) {
          console.log('JSON parse error:', e);
          return;
        }
        document.getElementById('pre-form').remove();
        document.getElementById('post-form-title').textContent = json.title;
        document.getElementById('post-form-content').textContent = json.content;
        document.getElementById("post-form").style.display = 'block';
        gtag('event', 'sign_up', { 'method': 'email' });
        gtag('event', 'generate_lead');
      }
    }
    contactAjax.open(method, action, true);
    // Request JSON format
    contactAjax.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    contactAjax.send(formData);

    event.preventDefault();
  });
  $('#sign-up-form').submit(function(event) {
    var company = $('input[name=company]').val();
    var phone = $('input[name=phone]').val();
    var email = $('input[name=email]').val();
    var formData = 'company='+company+'&email='+email+'&phone='+phone;
    var action = document.getElementById('sign-up-form').action;
    var method = document.getElementById('sign-up-form').method;
    // Disable inputs and buttons and show loading text
    var submitButton = document.getElementById('sign-up-form-submit');
    var submitButtonDefaultText = submitButton.innerHTML;
    submitButton.innerHTML = 'Laddar..';

    submitButton.disabled = true;
    $('input[name=company]').prop( "disabled", true );
    $('input[name=phone]').prop( "disabled", true );
    $('input[name=email]').prop( "disabled", true );

    // Make request
    var contactAjax = new XMLHttpRequest();
    contactAjax.onreadystatechange = function() {
      // completed request
      if (this.readyState === 4) {
        try {
          var json = JSON.parse(this.responseText)
        } catch (e) {
          //console.log('JSON parse error:', e);
          return;
        }
        document.getElementById('sign-up-form').remove();
        document.getElementById('post-form-title').textContent = json.title;
        document.getElementById('post-form-content').textContent = json.content;
        document.getElementById("post-form").style.display = 'block';
        gtag('event', 'sign_up', { 'method': 'email' });
        gtag('event', 'generate_lead');
      }
    }
    contactAjax.open(method, action, true);
    // Request JSON format
    contactAjax.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    contactAjax.send(formData);

    event.preventDefault();
  });
});
